import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';


const BannerOne = () => {

    const onPlayClick = () => {
        const url = "http://play.vgyani.com/";
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    return (
        <div className="banner">
            <div className="container">
                <div className="row align-items-end align-items-xl-start mt-4">
                    <div className="col-lg-6 pt-4 mt-4">
                        <div className="banner-content mt-4">
                            <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                                <h1 className="title">An <br /> <span> ENGAGING </span> &amp; <br/> <span> REWARDING </span> way of <span> PROBLEM SOLVING </span> </h1>
                                {/* Build beautiful website &amp; mobile apps. */}
                                <span className="subtitle">Where learning meets fun, offering an immersive practice experience through engaging gameplay</span>
                                {/* <Link to={""} className="axil-btn btn-fill-primary btn-large">Get Started</Link> */}
                                {/* http://play.vgyani.com/ */}
                            </AnimationOnScroll>
                        </div>
                    </div>
                    <div className="col-lg-6 pt-4 mt-4">
                        <div className="banner-thumbnail text-center mt-4">
                            <AnimationOnScroll animateIn="zoomIn" duration={2} delay={0} animateOnce={true}>
                                <h4 className="title">Boost your academic performace with</h4>
                                <div className="large-thumb">
                                    <img style={{height: "220px"}} src={process.env.PUBLIC_URL + "/images/banner/banner-logo.svg"} alt="Laptop" />
                                </div>
                                <Link onClick={onPlayClick} to={""} className="axil-btn btn-fill-primary btn-large play-btn mt-4">Play Now</Link>
                            </AnimationOnScroll>
                            {/* <AnimationOnScroll animateIn="slideInRight" duration={2} delay={300} animateOnce={true}>
                            <div className="large-thumb-2">
                                <img src={process.env.PUBLIC_URL + "/images/banner/laptop-poses.png"} alt="Laptop" />
                            </div>
                            </AnimationOnScroll>
                            <ul className="list-unstyled shape-group">
                                <li className="shape shape-1">
                                    <AnimationOnScroll animateIn="slideInLeft" duration={1} delay={800} animateOnce={true}>
                                        <img src={process.env.PUBLIC_URL + "/images/banner/chat-group.png"} alt="chat" />
                                    </AnimationOnScroll>
                                </li> 
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled shape-group-21">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"} alt="Bubble" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"} alt="Bubble" />
                </li>
                <li className="shape shape-3">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-4">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-5">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-6">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
                </li>
                <li className="shape shape-7">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default BannerOne;